.Header {
	/* background-color: black; */
	background-color:#2c826d;
  height: 40px;
  display: flex;
	justify-content: space-between;

}

.Header h1 {
	margin:0;
	padding-top: 3px;
	padding-left: 20px;
}

.Header h1 a {
	text-decoration: none;
	color:white;
}
.hyph {
	color:white;
  margin-right: 5px;
  margin-left: 5px;
}
.Header a {
	
	text-decoration: none;
	color:white;
}

.Header__logged-in{
	margin-right:20px;
	display: flex;
	flex-direction: column;
}

.Header__not-logged-in {
  display: flex;
	flex-direction: column;
	margin-right:20px;
}

 .hyph {
  display: none;
}

@media screen and (min-width: 450px) {
  .Header {
		align-items: baseline;
		
  }
  .Header h1 {
    font-size: 22pt;
  }

  .Header__not-logged-in   {
		flex-direction: row;
		
	}
	.Header__logged-in{
		flex-direction: row;
		
		
	}
	.Header a{
		
		padding-bottom: 20px;
	}

   .hyph {
    display: initial;
  }
}

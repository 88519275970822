.App-header{
  margin-left:10px;
  margin-right:10px;
  
}
.buttonContainer{
   
  display:flex;
  justify-content:space-evenly;    
}
.homepagebutton {
  color:white;
  background-color:#2c826d;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  /* font-weight: bold; */

}

.colorList {
  margin-bottom: 15px;
  padding: 4px 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.danger {
  margin-top:45px;
  background-color: #ffdddd;
  border-left: 6px solid #f44336;
}

.success {
  background-color: #ddffdd;
  border-left: 6px solid #4CAF50;
}

.info {
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
}

.warning {
  background-color: #ffffcc;
  border-left: 6px solid #ffeb3b;
}

.red{
  color: red;
  font-weight: bolder;
}


@media screen and (min-width: 450px) {


  .colorList{
    width:50%;
    margin-left:auto;
    margin-right: auto;
  }
}
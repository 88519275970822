@import url(https://fonts.googleapis.com/css?family=Raleway);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}



.Header {
	/* background-color: black; */
	background-color:#2c826d;
  height: 40px;
  display: flex;
	justify-content: space-between;

}

.Header h1 {
	margin:0;
	padding-top: 3px;
	padding-left: 20px;
}

.Header h1 a {
	text-decoration: none;
	color:white;
}
.hyph {
	color:white;
  margin-right: 5px;
  margin-left: 5px;
}
.Header a {
	
	text-decoration: none;
	color:white;
}

.Header__logged-in{
	margin-right:20px;
	display: flex;
	flex-direction: column;
}

.Header__not-logged-in {
  display: flex;
	flex-direction: column;
	margin-right:20px;
}

 .hyph {
  display: none;
}

@media screen and (min-width: 450px) {
  .Header {
		align-items: baseline;
		
  }
  .Header h1 {
    font-size: 22pt;
  }

  .Header__not-logged-in   {
		flex-direction: row;
		
	}
	.Header__logged-in{
		flex-direction: row;
		
		
	}
	.Header a{
		
		padding-bottom: 20px;
	}

   .hyph {
    display: inline;
    display: initial;
  }
}

.App-header{
  margin-left:10px;
  margin-right:10px;
  
}
.buttonContainer{
   
  display:flex;
  justify-content:space-evenly;    
}
.homepagebutton {
  color:white;
  background-color:#2c826d;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 8px 12px;
  /* font-weight: bold; */

}

.colorList {
  margin-bottom: 15px;
  padding: 4px 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.danger {
  margin-top:45px;
  background-color: #ffdddd;
  border-left: 6px solid #f44336;
}

.success {
  background-color: #ddffdd;
  border-left: 6px solid #4CAF50;
}

.info {
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
}

.warning {
  background-color: #ffffcc;
  border-left: 6px solid #ffeb3b;
}

.red{
  color: red;
  font-weight: bolder;
}


@media screen and (min-width: 450px) {


  .colorList{
    width:50%;
    margin-left:auto;
    margin-right: auto;
  }
}

  .wrapper {
    background-color:white;
    list-style-type: none;
    padding: 0;
    padding-top: 0px;
    border-radius: 3px;
    width:40%;
    margin:auto;
     /* border:1px solid #45aba6;  */
  }
  h2{
    margin-top:30px;
    text-align: center;
    font-weight: normal;
  }
  label {
    padding-right: 0.5em;
    text-align: center;
  }
 
  .form-row {
    display: flex;
    justify-content: center;
    padding: .5em;
  }
  .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 0 1;
  }
  .form-row > input {
    flex: 0 1;
  }
  .form-row > input,
  .form-row > button {
    padding: .5em;
  }

  .form-row > button {
   border:none;
    background-color:#2c826d;
    color:white;
    font-size: 1.1em;
    border-radius: 4px;
    padding: 8px 12px;
    font-weight: bold;
  }

  .buttonContainer{
   
    display:flex;
    justify-content:space-evenly;    
  }
  .homepagebutton {
    color:white;
    background-color:#2c826d;
    font-size: 1.1em;
    border-radius: 4px;
    padding: 8px 12px;
    /* font-weight: bold; */
	
 } 

.addHabitDiv{
  
display:flex;
flex-direction: column;
}
.addHabitLable{
  margin-top:20px;
}
.addHabitInput{
  
  margin-top:10px;
  margin-bottom:10px;
  font-size:16px;
  height:35px;

}

.addButtonForm{
   background-color: #2c826d;
   margin-bottom: 10px;
   height: 30px;
   border: none;
   color: white;
   font-size: 16px;
   cursor: pointer;
  
  
}
.demo{
  margin:0px;
  padding:0px;
  text-align: center;
  font-style: italic;
}


@media screen and (min-width: 450px) {
  .addHabitDiv{
    margin-top:30px;
 
  margin:auto;
  }


}



.ulHabitlist{
  list-style-type:none;
  padding:0;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-evenly;
  
 }
li{
  list-style: none;
}
.liTitle{
  padding-right:10px;
  margin-top:10px;
  padding-left:10px;
  padding-top:20px;
width:100%;
height: 60px;
background-color: #ddffdd;
border-left: 6px solid #4CAF50;

}

.liPercentage{
  margin-top:10px;
  height: 60px;
padding-top:20px;
width: 20%;
text-align: center;
background-color: #e7f3fe;

 }

.liButton{
  margin-top:10px;
  width:100%;
  text-align: center;
  /* background-color: #ffffcc; */
  /* background-color:#ddffdd; */
 border:none;
  
}


.text{
 font-weight:bold;
}

.dateContainer{
  /* border:1px solid red; */
  

  display:flex;
  justify-content: center;
  align-items: center;
  
}

.date{
  margin-top:10px;
  text-align: center;
  width:20%;
  background-color: #e7f3fe;
  
}


.addNewHabitButtonDiv{
 display:flex;
 flex-direction: column;
 align-items: center;
}
.addButton{
  border:none;
margin-top:10px;
height: 35px;
color:white;
font-size:16px;
background-color:#2c826d; 
/* border:1px solid #4CAF50; */
}

.textContainer{
  display:flex;
  flex-wrap:wrap;
}
.inputTitle{
  padding-right:10px;
  margin-top:10px;
  padding-left:10px;
  width:100%;
  height: 60px;
  background-color: #ddffdd;
  border:solid 1px #4CAF50;
}
.pLable{
  padding-left:10px;
  margin-top:10px;
  width:100%;
  border: 1px solid #2196F3; 
background-color: #e7f3fe;
}
.percentage{
  border: 1px solid #2196F3;
  margin-right: -1px;
  margin-top:10px;
  height: 60px;
font-size: 1.2em;
width: 20%;
text-align: center;
background-color: #e7f3fe;

}



.editbutton{
  border:none;
 margin:5px;
margin-left:60%;

background-color: #2c826d; 
border:1px solid #4CAF50;
color:white;
font-size:16px;
width:50px
}

.deleteButton{
  background-color: #2c826d; 
border:1px solid #4CAF50;
  font-size:16px;
  margin:5px;
  color:white;
}

.updateButton{
  border: none;
  font-size:16px;
  margin:5px;
  color:white;
  background-color: #4CAF50;
}

@media screen and (min-width: 450px) {
.ulHabitlist{
  width:50%;
  margin:auto;
}

.textContainer{
  display:flex;
  flex-direction: row;
  width:50%;
  margin:auto;
}
}



  .wrapper {
    background-color:white;
    list-style-type: none;
    padding: 0;
    padding-top: 0px;
    border-radius: 3px;
    width:40%;
    margin:auto;
     /* border:1px solid #45aba6;  */
  }
  h2{
    margin-top:30px;
    text-align: center;
    font-weight: normal;
  }
  label {
    padding-right: 0.5em;
    text-align: center;
  }
 
  .form-row {
    display: flex;
    justify-content: center;
    padding: .5em;
  }
  .form-row > label {
    padding: .5em 1em .5em 0;
    flex: 0;
  }
  .form-row > input {
    flex: 0;
  }
  .form-row > input,
  .form-row > button {
    padding: .5em;
  }

  .form-row > button {
   border:none;
    background-color:#2c826d;
    color:white;
    font-size: 1.1em;
    border-radius: 4px;
    padding: 8px 12px;
    font-weight: bold;
  }

  .buttonContainer{
   
    display:flex;
    justify-content:space-evenly;    
  }
  .homepagebutton {
    color:white;
    background-color:#2c826d;
    font-size: 1.1em;
    border-radius: 4px;
    padding: 8px 12px;
    /* font-weight: bold; */
	
 } 

.addHabitDiv{
  
display:flex;
flex-direction: column;
}
.addHabitLable{
  margin-top:20px;
}
.addHabitInput{
  
  margin-top:10px;
  margin-bottom:10px;
  font-size:16px;
  height:35px;

}

.addButtonForm{
   background-color: #2c826d;
   margin-bottom: 10px;
   height: 30px;
   border: none;
   color: white;
   font-size: 16px;
   cursor: pointer;
  
  
}
.demo{
  margin:0px;
  padding:0px;
  text-align: center;
  font-style: italic;
}


@media screen and (min-width: 450px) {
  .addHabitDiv{
    margin-top:30px;
 
  margin:auto;
  }


}



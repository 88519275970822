.ulHabitlist{
  list-style-type:none;
  padding:0;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-evenly;
  
 }
li{
  list-style: none;
}
.liTitle{
  padding-right:10px;
  margin-top:10px;
  padding-left:10px;
  padding-top:20px;
width:100%;
height: 60px;
background-color: #ddffdd;
border-left: 6px solid #4CAF50;

}

.liPercentage{
  margin-top:10px;
  height: 60px;
padding-top:20px;
width: 20%;
text-align: center;
background-color: #e7f3fe;

 }

.liButton{
  margin-top:10px;
  width:100%;
  text-align: center;
  /* background-color: #ffffcc; */
  /* background-color:#ddffdd; */
 border:none;
  
}


.text{
 font-weight:bold;
}

.dateContainer{
  /* border:1px solid red; */
  

  display:flex;
  justify-content: center;
  align-items: center;
  
}

.date{
  margin-top:10px;
  text-align: center;
  width:20%;
  background-color: #e7f3fe;
  
}


.addNewHabitButtonDiv{
 display:flex;
 flex-direction: column;
 align-items: center;
}
.addButton{
  border:none;
margin-top:10px;
height: 35px;
color:white;
font-size:16px;
background-color:#2c826d; 
/* border:1px solid #4CAF50; */
}

.textContainer{
  display:flex;
  flex-wrap:wrap;
}
.inputTitle{
  padding-right:10px;
  margin-top:10px;
  padding-left:10px;
  width:100%;
  height: 60px;
  background-color: #ddffdd;
  border:solid 1px #4CAF50;
}
.pLable{
  padding-left:10px;
  margin-top:10px;
  width:100%;
  border: 1px solid #2196F3; 
background-color: #e7f3fe;
}
.percentage{
  border: 1px solid #2196F3;
  margin-right: -1px;
  margin-top:10px;
  height: 60px;
font-size: 1.2em;
width: 20%;
text-align: center;
background-color: #e7f3fe;

}



.editbutton{
  border:none;
 margin:5px;
margin-left:60%;

background-color: #2c826d; 
border:1px solid #4CAF50;
color:white;
font-size:16px;
width:50px
}

.deleteButton{
  background-color: #2c826d; 
border:1px solid #4CAF50;
  font-size:16px;
  margin:5px;
  color:white;
}

.updateButton{
  border: none;
  font-size:16px;
  margin:5px;
  color:white;
  background-color: #4CAF50;
}

@media screen and (min-width: 450px) {
.ulHabitlist{
  width:50%;
  margin:auto;
}

.textContainer{
  display:flex;
  flex-direction: row;
  width:50%;
  margin:auto;
}
}
